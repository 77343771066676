<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-card class="mb-3">
          <template #header>
            <div class="d-flex flex-row">
              <p class="flex-fill mt-1 mb-0">Call Report</p>
              <b-button @click="() => fetchCallReport({ refresh: true })" size="sm" variant="secondary">Refresh</b-button>
            </div>
          </template>
          <b-card-body class="text-center" v-if="callReportLoading">
            <p class="my-4"><b-spinner variant="secondary" /></p>
          </b-card-body>
          <b-card-body class="text-center" v-if="!callReportItems && !callReportLoading">
            <p class="my-4"><span class="text-danger">Could not refresh the report. Please try again in a couple of minutes.</span></p>
          </b-card-body>
          <b-table responsive striped head-variant="dark" :fields="callReportFields" :items="callReportItems" v-if="!callReportLoading && callReportItems && callReportFields"></b-table>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card class="mb-3" no-body header="Scheduled Callbacks (Today)">
          <b-card-body class="text-center" v-if="!scheduledCallsLoading && scheduledCalls.length === 0">
            <p><b-icon class="display-3" icon="cloud-arrow-down" /></p>
            <p class="h5 font-weight-normal">No scheduled calls found for today.</p>
          </b-card-body>
          <b-card-body class="text-center" v-if="scheduledCallsLoading">
            <p class="my-4"><b-spinner variant="secondary" /></p>
          </b-card-body>
          <b-list-group flush v-if="!scheduledCallsLoading && scheduledCalls.length > 0">
            <b-list-group-item class="px-4 py-3" :key="callback.id" v-for="callback in scheduledCalls">
              <div class="d-flex flex-row justify-items-start">
                <div class="align-self-center mr-2">
                  <b-checkbox :value="callback.id" v-model="callsCompleted" @change="showCompleteScheduledCallModal(callback.id)"></b-checkbox>
                </div>
                <div class="flex-fill">
                  <div class="d-flex flex-row justify-space-between">
                    <p class="h5 mb-1 flex-fill">
                      <router-link :to="`/energy/${ get(callback, 'customer.confirmed_at', false) ? 'customers' : 'leads' }/${ get(callback, 'customer.id') }`">
                        {{ get(callback, 'customer.company_name') }}
                        <small><b-badge class="ml-1" variant="warning" v-if="callback.overdue_by">{{ callback.overdue_by }} hrs</b-badge></small>
                      </router-link>
                    </p>
                    <friendly-date not-relative time-only class="text-muted mb-0" :date="moment(get(callback, 'scheduled_for')).format('YYYY-MM-DD HH:mm:ss')" />
                  </div>
                  <p class="text-muted mb-0">Call with {{ get(callback, 'contact_name') }} ({{ get(callback, 'contact_number') }})</p>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-card-body v-if="scheduledCallsPagination.total > scheduledCallsPagination.per">
            <b-pagination align="right" class="mb-0" @change="onScheduledCallsPaginate" :per-page="scheduledCallsPagination.per" :total-rows="scheduledCallsPagination.total" v-model="scheduledCallsPagination.current"></b-pagination>
          </b-card-body>
        </b-card>
        <b-modal cancel-title="Cancel" cancel-variant="dark" ok-title="Complete" ok-variant="secondary"
          id="complete-scheduled-call" size="xl" title="Complete Scheduled Call"
          :ok-disabled="completeCallOkDisabled"
          @cancel="cancelCompleteScheduledCallModal" @ok.prevent="submitCompleteScheduledCallModel">
          <template v-slot:modal-ok>
            <span v-if="!scheduledCallsCompleting && !scheduledCallsCreating && !scheduledCallsLoading">Complete</span><b-spinner class="mx-4" small variant="light" v-if="scheduledCallsCompleting || scheduledCallsCreating || scheduledCallsLoading" />
          </template>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Call Outcome">
                <b-form-radio v-model="callOutcome" value="completed">Completed</b-form-radio>
                <b-form-radio v-model="callOutcome" value="rescheduled">Rescheduled</b-form-radio>
                <b-form-radio v-model="callOutcome" value="no-answer-rescheduled">No Answer (Rescheduled)</b-form-radio>
                <b-form-radio v-model="callOutcome" value="no-answer">No Answer (Not rescheduled)</b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Contact Details">
                <b-form-input placeholder="Contact Name" v-model="callOutcomeContactName" class="mb-2" />
                <b-form-input placeholder="Contact Number" v-model="callOutcomeContactNumber" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Rescheduled For" v-if="callOutcome === 'rescheduled' || callOutcome === 'no-answer-rescheduled'">
                <b-form-datepicker v-model="callRescheduleDate" class="mb-2"></b-form-datepicker>
                <b-input-group>
                  <b-form-input v-model="callRescheduleTime"></b-form-input>
                  <b-input-group-append>
                    <b-form-timepicker button-only right v-model="callRescheduleTime"></b-form-timepicker>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Notes">
                <b-form-textarea v-model="callOutcomeNotes"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import { mapActions, mapGetters } from 'vuex'

import FriendlyDate from '../components/FriendlyDate'
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  components: {
    FriendlyDate
  },
  computed: {
    ...mapGetters('scheduledCalls', {
      scheduledCallsPagination: 'pagination',
      scheduledCallsCompleting: 'completing',
      scheduledCallsCreating: 'creating',
      scheduledCallsLoading: 'loading',
      scheduledCalls: 'data'
    }),
    ...mapGetters('reporting', ['callReportFields', 'callReportItems', 'callReportLoading']),
    completeCallOkDisabled () {
      return !this.callOutcome ||
        (!this.callOutcomeContactName || !this.callOutcomeContactNumber) ||
        (
          (this.callOutcome === 'rescheduled' || this.callOutcome === 'no-answer-rescheduled') &&
          (!this.callRescheduleTime || !this.callRescheduleDate)
        )
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('scheduledCalls/reset')
    return next()
  },
  created () {
    this.fetchScheduledCalls({ dashboard: true })
    this.fetchCallReport({})
  },
  data () {
    return {
      currentCallId: 0,
      currentCallCustomerId: 0,

      callOutcome: null,
      callsCompleted: [],
      callOutcomeNotes: null,
      callOutcomeContactName: null,
      callOutcomeContactNumber: null,
      callRescheduleDate: null,
      callRescheduleTime: '09:00:00'
    }
  },
  methods: {
    get,
    ...mapActions('scheduledCalls', {
      fetchScheduledCalls: 'fetchData',
      createScheduledCall: 'createCall',
      completeScheduledCall: 'completeCall'
    }),
    ...mapActions('reporting', { fetchCallReport: 'callReport' }),
    cancelCompleteScheduledCallModal () {
      this.callsCompleted.splice(this.callsCompleted.indexOf(this.currentCallId, 1))
      this.$bvModal.hide('complete-scheduled-call')
    },
    onScheduledCallsPaginate (page) {
      this.fetchScheduledCalls({ dashboard: true, page })
    },
    showCompleteScheduledCallModal (id) {
      if (this.callsCompleted.indexOf(id) === -1) {
        const call = this.scheduledCalls.find(c => c.id === id)

        this.currentCallId = call.id
        this.currentCallCustomerId = call.customer_id
        this.callOutcomeContactName = call.contact_name
        this.callOutcomeContactNumber = call.contact_number

        this.$bvModal.show('complete-scheduled-call')
      }
    },
    submitCompleteScheduledCallModel () {
      this.completeScheduledCall({
        id: this.currentCallId,
        payload: {
          status: this.callOutcome,
          notes: this.callOutcomeNotes
        }
      }).then(res => {
        if (res.data.status === 'completed' || res.data.status === 'no-answer') {
          this.$bvModal.hide('complete-scheduled-call')
          return
        }

        return this.createScheduledCall({
          customer_id: this.currentCallCustomerId,
          contact_name: this.callOutcomeContactName,
          contact_number: this.callOutcomeContactNumber,
          scheduled_for: this.moment(this.callRescheduleDate).add(this.callRescheduleTime).format('YYYY-MM-DD HH:mm:ss')
        }).then(res => {
          this.$bvModal.hide('complete-scheduled-call')
        })
      })
    }
  }
}
</script>
